@page {
  size: A4 landscape;
  margin: 0;
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print{
  @page {
    size: A4 landscape;
    margin: 0;
  }
  @page {size: landscape}
  html, body {
    width: 297mm;
    height: 210mm;
    border: 1px solid white;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .pagebreak {
    page-break-before: always;
  }
}

.bill-1{
  width: 297mm;
  height: 210mm;
  padding: 0 5px 0 5px;
  font-size: 0.9rem;
  /* padding-bottom: 15px; */
  /* font-family: 'JasmineUPC' !important; */
}

.bill-1 p{
  margin: 0;
}

.bill-1 label{
  margin: 0;
}

.bill-1 .address .line{
  border-bottom: 1px solid;
}

.bill-1 .address .line .qr{
  border-left: 15px solid #f82d92;
  margin-left: 30px;
  padding-left: 5px;
}

.bill-1 .address .line .qr img{
  width: 60px;
  padding-bottom: 2px;
}

.bill-1 .address .line .qr .phone{
  display: flex;
}

.bill-1 .address .line .qr .phone img{
  width: 25px;
}

.bill-1 .address .line .qr .phone p{
  align-self: center;
}

.bill-1 .inline{
  display: flex;
  width: 100%;
}

.bill-1 .table-group .table-header{
  display: flex;
  width: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.bill-1 .table-body{
  /* border-bottom: 1px solid; */
  /* min-height: 200px;
  max-height: 220px; */
}

.bill-1 .table-body .table-field{
  display: flex;
  width: 100%;
  margin-top: -1px;
  min-height: 20px;
}

.bill-1 .table-body .table-field:first-child{
  border-top: 1px solid;
  text-align: left;
}

.bill-1 .table-body .table-field:last-child{
  border-bottom: 1px solid;
}

.bill-1 .table-body .table-field div{
  width: 20%;
  border-left: 1px solid;
  display: flex;
  align-items: end;
  justify-content: center;
}

.bill-1 .table-body .table-field div:first-child{
  width: 40%;
  border-left: none;
  text-align: left;
}

.bill-1 .table-body .table-field div:last-child{
  border-right: none;
}

.bill-1 .table-group .table-header .title{
  width: 20%;
  text-align: center;
  padding: 5px;
  font-weight: bold;
}

.bill-1 .table-group .table-header .title:first-child{
  width: 40%;
  text-align: center;
  padding: 5px;
  font-weight: bold;
}

.bill-1 .customer-info{
  width: 55%;
}

.bill-1 .invoice{
  width: 45%;
}

.bill-1 .invoice .label-receipt{
  background-color: black;
  color: white;
  text-align: center;
  font-size: 22px;
  font-weight: 200;
  padding-top: 5px;
}

.bill-1{
  height: 100%;
  display: flex;
}

.bill-1 .original{
  padding: 25px 10px 25px 25px;
  height: 100%;
  width: 50%;
  border-right: 1px dashed;
}

.bill-1 .copy{
  padding: 25px 25px 25px 10px;
  height: 100%;
  width: 50%;
}

.bill-1 .header{
  width: 100%;
  display: flex;
}

.bill-1 .header img{
  width: 40%;
}

.bill-1 .header .address{
  width: 60%;
}

.bill-1 .header .address .address-2{
  margin-left: 55px;
}

.bill-1 .car-info{
  width: 25%;
}

.bill-1 .note{
  width: 25%;
}

.bill-1 .price{
  width: 50%;
}

.bill-1 .price .inline{

}

.bill-1 .price .inline .title{
  width: 155px;
  text-align: right;
  margin-right: 5px;
}

.bill-1 .signature{
  display: flex;
  margin-top: 30px;
  width: 100%;
  margin-right: auto;
}

.bill-1 .signature .employee{
  width: 50%;
  border-top: 1px solid;
  margin: 10px;
  text-align: center;
  padding-top: 10px;
}

.bill-1 .signature .car-owner{
  width: 50%;
  border-top: 1px solid;
  margin: 10px;
  text-align: center;
  padding-top: 10px;
}

