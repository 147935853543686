
.preview-p{
  height: 29.7cm;
  width: 21cm;
  margin: auto;
  border: 1px solid;
  display: flex;
  justify-content: center;
}

.preview-l{
  height: 21cm;
  width: 29.7cm;
  margin: auto;
  border: 1px solid;
  background-color: white;
  display: flex;
  justify-content: center;
}

.preview-2{
  justify-content: center;
  display: flex;
  height: auto;
  width: auto;
  margin: auto;
  background-color: white;
}

.preview-btn{
  &:hover{
    background-color: goldenrod;
    color: white;
  }
  &:disabled{
    background-color: lightgray;
    color: darkgrey;
  }
  &.active{
    color: white !important;
    background: #682c80 !important;
    transform: scale(1.2);
    transition: all .2s ease-in-out;
  }
}

.preview-btn-action{
  min-width: 150px;
  border: none;
  margin: 5px;
  p{
    margin: 0;
  }
  &:hover{
    background: #682c80;
    p{
      color: white;
      margin: 0;
      transform: scale(1.2);
      transition: all .2s ease-in-out;
    }
  }
}

.preview-group{
  display: flex;
  width: 100%;
  justify-content: space-between;
  button{
    width: 100%;
  }
}