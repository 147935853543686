@page {
  size: A4 portrait;
  margin: 0;
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print{
  @page {
    size: A4 portrait;
    margin: 0;
  }
  @page {size: portrait}
  html, body {
    width: 210mm;
    height: 297mm;
    border: 1px solid white;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .pagebreak {
    page-break-before: always;
  }
}

.bill-6{
  margin-bottom: -1px;
  width: 210mm;
  height: 297mm;
  background-color: white;
  /* margin-top: 10px; */
  /* padding: 0 5px 0 5px; */
  padding: 30px;
  padding-left: 15px !important;
}

.bill-6 p{
  margin: 0;
}

.bill-6 label{
  margin: 0;
}

.bill-6 .inline{
  display: flex;
}

.bill-6 .inline.between{
  justify-content: space-between;
}

.bill-6 .top{
  text-align: center;
}

.bill-6 .detail .line{
  display: flex;
  justify-content: space-between;
}

.bill-6 .detail .line label{
  text-align: start;
}

.bill-6 .bill-6-label{
  min-width: 80px;
  max-width: 80px;
  /* margin-right: 10px !important; */
}

.table-bill-6 .table-head{
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  border: 1px solid;
  /* border-bottom: none; */
  font-weight: bold;
}

.table-bill-6 .table-head div:first-child{
  width: 10%;
  border-right: 1px solid;
}

.table-bill-6 .table-head div:nth-child(2){
  width: 30%;
  border-right: 1px solid;
}

.table-bill-6 .table-head div:nth-child(3){
  width: 15%;
  border-right: 1px solid;
}

.table-bill-6 .table-head div:nth-child(4){
  width: 15%;
  border-right: 1px solid;
}

.table-bill-6 .table-head div:last-child{
  width: 30%;
}

.table-bill-6 .body-field{

}

.table-bill-6 .table-body{
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  border-left: 1px solid;
  border-right: 1px solid;
}

.table-bill-6 .table-body div:first-child{
  width: 10%;
  border-right: 1px solid;
}

.table-bill-6 .table-body div:nth-child(2){
  width: 30%;
  border-right: 1px solid;
}

.table-bill-6 .table-body div:nth-child(3){
  width: 15%;
  border-right: 1px solid;
}

.table-bill-6 .table-body div:nth-child(4){
  width: 15%;
  border-right: 1px solid;
}

.table-bill-6 .table-body div:last-child{
  width: 30%;
}

.table-bill-6 .table-body:last-child{
  /* border-bottom: 1px solid; */
}

.table-bill-6 .price-field{
  border: 1px solid;
}

.table-bill-6 .price-area{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.table-bill-6 .price-field .price-area div:first-child{
  width: 10%;
  border-right: 1px solid;
}

.table-bill-6 .price-field .price-area div:nth-child(2){
  width: 30%;
  border-right: 1px solid;
}

.table-bill-6 .price-field .price-area div:nth-child(3){
  width: 30%;
  border-right: 1px solid;
}

.table-bill-6 .price-field .price-area div:nth-child(4){
  width: 30%;
}

.table-bill-6 .price-field .price-area div:nth-child(4){
  border-bottom: 1px solid;
}

.table-bill-6 .price-field .price-area:last-child div:last-child{
  border-bottom: none;
}

.bill-6 .signature{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.bill-6 .signature .signature-field{
  width: 20%;
}

.bill-6 .signature .signature-field .signature-area .signature-name{
  width: 100%;
  border-bottom: 1px dotted;
  margin-bottom: 30px;
}

.bill-6 .signature .signature-field .signature-area .signature-date{
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px dotted;
  margin-top: 30px;
  height: 20px;
}

.bill-6 .signature .signature-field .signature-area .signature-date p{
  position: absolute;
  text-align: end;
  bottom: -45%;
}

.bill-6 .signature .signature-field .signature-area .signature-date p span{
  background: white;
}

.bill-6 .signature .signature-field .signature-area .signature-date p:first-child{
  width: 33%;
}
.bill-6 .signature .signature-field .signature-area .signature-date p:last-child{
  width: 66%;
}

.bill-6 .signature .signature-field .signature-position{
  width: 100%;
  margin-top: 10px;
  text-align: center;
}

.bill-6 .table-bill-6 .price-field{
  font-weight: bold;
}

