#overlay {
  display: none;
  z-index: 999999;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.35);
  .spinner-field{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-border{
      width: 6rem;
      height: 6rem;
      font-size: 55px;
      color: #682c80;
    }
    &-fixed{
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      @extend .spinner-field;
    }
  }
}