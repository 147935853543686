            /*------------Dashboard------------*/
.icon {
    width: auto;
    height: 50px;
}
.dash-icon {
    text-align: center;
    margin: 0.8rem 0.8rem 0.8rem 0.8rem;
}
.btn {
    border-width: 2px;
    border-style: outset;
    border-color: buttonface;
    /* border-right-color: #424242;
    border-bottom-color: #424242; */
    background: silver;
    color: black;
    padding: 5 5 5 5px;
    border-radius: 1px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(229, 229, 229, 0.5);
}
.btn-modal {
  border-width: 2px;
    border-style: outset;
    border-color: buttonface;
    /* border-right-color: #424242;
    border-bottom-color: #424242; */
    background: silver;
    color: black;
    padding: 5 5 5 5px;
    border-radius: 1px;
    width: 170px;
    height: 40px;
    box-shadow: 0 4px 5px -2px gray;
}
.menu-right {
    margin-left: auto;
}

            /*------------Login------------*/

.logo{
    height: 100% !important;
    /* height: 20px; */
    position: relative;
}
.container{
    max-width: 450px;
    width: 50%;
    align-self: center;
    padding-top: 5rem;
}
.card-header{
    background-color: rgba(196, 196, 196, 0.5);
    height: 40px;
    width: 432;
}
.card-body{
    /* background-color: #C4C4C4; */
    background-color: #FFFFFF;;
    border-radius: 0.50rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-close{
    line-height: 1rem;
    font-size: 2rem;
    background-color: #C4C4C4;
    border: 1px solid rgba(229, 229, 229, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-submit{
    border: 1px solid rgba(229, 229, 229, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 320px;
    width: 100%;
    height: 38px;
}
.data-input {
    max-width: 320px;
    width: 100%;
    height: 38px;
    border-width: 0px;
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

            /*------------Modal------------*/

.logo{
    height: 100% !important;
    /* height: 20px; */
    position: relative;
}
.modal-content{
    box-shadow: 0;
    border: 0;
    border-right-color: none;
    border-bottom-color: none;
    background: none;
    width: 100%;
}
.modal-header{
    background-color: rgba(196, 196, 196, 0.5);
    border: 0;
    height: 40px;
}
.modal-body{
    background-color: #C4C4C4;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
}
.modal-footer{
    padding: 6px;
}
.btn-close{
    line-height: 1rem;
    font-size: 2rem;
    background-color: #C4C4C4;
    border: 1px solid rgba(229, 229, 229, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-action {
    width: 10rem;
    margin-inline: 1rem;
}
.modal-icon {
    height: 34px;
    width: 34px;
    padding: 4px;
    text-align: center;
}
.btn-icon {
    padding: 0.2rem;
    height: 45px;
}
.t-header {
    background: #C4C4C4;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.t-body {
    background: #FFFFFF;
    border: 1px solid #7A7A7A;
    box-sizing: border-box;
}

            /*------------Navbar------------*/

.menu-icon {
    width: 20px;
    height: 20px;
  }
  .navbar {
    background-color: silver;
    cursor: default;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
    padding: 5px 0 !important;
  }
  .navbar .start-button {
    background: #C4C4C4;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0;
    padding-right: 5px;
    margin-left: 5px;
  }
  .navbar .end-button {
    background: #C4C4C4;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-right: 5px;
    padding: 5px;
  }
  .navbar-right {
    margin-left: auto;
  }
  .login-name {
    color: #7A7A7A !important;
  }
  .text-margin {
      margin-left: auto;
  }
  .text-margin-right {
      margin-right: auto;
  }

  /*------ toggle switch --------*/
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .delete-package {
    width: 171px;
    height: 42px;

    background: #c4c4c4 !important;
    border: 1px solid rgba(229, 229, 229, 0.5) !important;
    box-sizing: border-box !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 0;
    color: black;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #000000;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .toggle-padding {
      padding-right: 60px;
  }

  .padding-label {
    padding-right: 200px;
  }
  .modal-margin-right {
    margin-left: -10px;
  }
  .font-weight {
    font-weight: 1000;
  }
  .pagination-footer {
    margin-left: auto;
  }

/*------ profile --------*/
.avatar-preview {
  width: 130px;
  height: 130px;
  object-fit: cover;
}
.inputfile, .inputperform {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  color: white;
  background-color: #616161;
  display: inline-block;
  cursor: pointer;
  width: 130px;
  text-align: center;
}

.inputperform + label {
color: white;
background-color: #616161;
display: inline-block;
cursor: pointer;
width: 200px;
}
.profile-form {
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
text-align: left;
}

.block-underline{
  text-decoration: none !important;
}

.block-underline:hover{
  text-decoration: none !important;
}

.b2b-button{
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.b2b-button .side{
  padding: 3px;
  min-width: 97px;
  background: #d4d1d1;
  /* background: #C4C4C4; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.b2b-button .side .field{
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 16px;
  min-height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  background: rgba(229, 229, 229, 0.5);
  box-shadow: inset 0px 4px 8px 2px rgb(0 0 0 / 15%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.b2b-button .side .field:hover{
  background: unset;
}

.package-logo-field{
  min-width: 80px;
  max-width: 80px;
  position: relative;
}
.package-logo-field:hover{
  transition: all .2s ease-in-out;
  transform: scale(2.5) translate(22.5%);
  z-index: 999;
}
.package-logo{
  cursor: pointer;
  width: 100%;
}

.package-logo-field:not(:hover){
  transition: all .2s ease-in-out;
  transform: scale(1);
}

input:disabled {
  background: #dddddd !important;
}

.custom-dropdown .dropdown-toggle::after{
  display: none;
}
.custom-dropdown .arrow{
  background-color: #c4c4c4 !important;
  max-width: 44px;
  max-height: 44px;
  min-width: 44px;
  min-height: 44px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBvZI9DkRAAEY/2WS7TbbYq2zBTbiDQyho1O6iU6jEBVRKiSMIwTdBCDOMxkteRDJv/jLA0xjzN6J/zSaj7vIjooYOF4ox5n4mXyMMZFv40fIkqugHChzaK0IbJ7xpLIkSaCAO326ijlrQJNyEHm7wwnRRBf2qBsgQK9U0pTluYmB9WQdGu3s2F8czfmMAAAAASUVORK5CYII=') !important;
  background-position: center !important;
  background-size: 14px !important;
  background-repeat: no-repeat !important;
}

.custom-dropdown .limit{
  max-height: 200px;
  overflow-y: scroll;
}

.toast-container{
  z-index: 1;
}

a{
  color: rgba(196, 196, 196, 0.768627451) !important;
}

.add-on-button{
  border: none !important;
  border-top: 1px solid rgb(206, 212, 218) !important;
  border-right: 1px solid rgb(206, 212, 218) !important;
  border-bottom: 1px solid rgb(206, 212, 218) !important;
  width: 44px !important;
  height: 44px !important;
  z-index: 0 !important;
}

.pointer{
  cursor: pointer !important;
}

.break-css{
  width: 100% !important;
  margin: 0 !important;
}

.job-appointment{
  width: 100%;
  background: white;
  border: 1px solid;
  min-height: 90px;
  display: flex;
  padding: 10px;
}

.job-appointment .title{
  display: flex;
  align-items: center;
  width: 50%;
}

.job-appointment .title .pg{
  margin-right: 5px;
}

.job-appointment .title .branch{
  
}

.job-appointment .date{
  display: flex;
  align-items: center;
  width: 10%;
}

.job-appointment .time{
  display: flex;
  align-items: center;
  width: 10%;
}

.job-appointment .switch{
  display: flex;
  align-items: center;
  align-self: center;
  width: 10%;
}

.job-appointment .edit{
  display: flex;
  align-items: center;
  width: 20%;
}

.dash-content {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 25px;
}

.dash-main-text {
  color: #5D00A4 !important;
}

.dash-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dash-gradient {
  background: rgb(81,4,165) !important;
  background: linear-gradient(180deg, rgba(81,4,165,1) 50%, rgba(128,57,185,1) 100%) !important;
  color: #FFFFFF !important;
}
