@page {
  size: A4 portrait;
  margin: 0;
}

@media print{
  @page {
    size: A4;
    margin: 0;
  }
  @page {size: portrait}
  html, body {
    width: 210mm;
    height: 297mm;
    border: 1px solid white;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

.bill-8{
  width: 210mm;
  height: 297mm;
  background-color: white;
  margin-bottom: -1px;
}

.bill-8 .bill-8-field{
  /* height: 21cm;
  width: 29.7cm; */
}

.bill-8 .highlight{
  background-color: #fa90f2;
}

.bill-8 .table-header{
  display: flex;
  width: 100%;
}

.bill-8 .table-body{
  margin-top: -2px;
}

.bill-8 .table-body .table-field{
  display: flex;
  width: 100%;
  margin-top: -2px;
}

.bill-8 .table-body .table-field:first-child{
  border-top: 2px solid;
}

.bill-8 .table-body .table-field:last-child{
  border-bottom: 2px solid;
}

.bill-8 .table-body .table-field div{
  width: 25%;
  border-left: 2px solid;
}

.bill-8 .table-body .table-field div:last-child{
  border-right: 2px solid;
}

.bill-8 .table-header .title{
  width: 25%;
  text-align: center;
  border: 2px solid;
  padding: 5px;
  font-weight: bold;
}

.bill-8 .table-header .title:first-child{
  border-right: none;
}

.bill-8 .table-header .title:not(:first-child):not(:last-child){
  border-right: none;
}

.bill-8 label{
  font-weight: bold;
  font-size: 12px;
}

.bill-8 p{
  margin: 0;
  font-size: 12px;
}

.bill-8 div{
  font-size: 12px;
}

.bill-8-field{
  /* height: 100vh !important; */
}

.bill-8 .half-top{
  /* height: 50%; */
  min-height: calc(297mm / 2);
  /* max-height: 50% !important; */
  max-height: calc(297mm / 2);
  border-bottom: 1px dashed darkgray;
}

.bill-8 .half-bottom{
  /* height: 50%; */
  min-height: calc(297mm / 2);
  /* max-height: 50% !important; */
  max-height: calc(297mm / 2);
}

.bill-8 .header-group{
  display: flex;
  padding: 0 0 0 15px;
}

.bill-8 .body-group{
  padding: 0 50px 0 15px;
}

.bill-8 .header-group .location{
  width: 44%;
}

.bill-8 .header-group .car-info{
  width: 21.5%;
}

.bill-8 .header-group .car-info-per{
  width: 12.5%;
}

.bill-8 .header-group .flag{
  width: 21%;
}

.bill-8 .header-group .flag img{
  width: 100%;
  padding: 0 20px 5px 20px;
}

.bill-8 .header-group .flag .label{
  background-color: black;
  color: white;
  padding: 0 20px 0 20px;
  font-size: 16px;
}


.bill-8 .footer-group{
  padding: 10px 50px 0 15px;
  display: flex;
}


.bill-8 .footer-group .customer-info{
  width: 33.33%;
  padding-right: 10px;
}

.bill-8 .footer-group .note{
  width: 33.33%;
}

.bill-8 .footer-group .note .box{
  border: 2px solid;
  padding: 5px;
}

.bill-8 .footer-group .price{
  width: 33.33%;
  text-align: center;
  padding: 5px 5px 5px 15px;
}

.bill-8 .footer-group .price .inline{
  display: flex;
  justify-content: space-between;
}

.bill-8 .footer-group .price .inline p{
  width: 120px;
}

.bill-8 .footer-group .price .inline p:first-child{
  text-align: left;
}

.bill-8 .footer-group .price .inline p:nth-child(2){
  text-align: left;
}

.bill-8 .footer-group .price .signature{
  display: flex;
  margin-top: 10px;
}

.bill-8 .footer-group .price .signature .employee, .sale, .car-owner{
  width: 50%;
  border-top: 2px solid;
  margin: 10px;
}

.bill-8 .half-type{
  text-align: end;
  padding-right: 5px;
  font-weight: bold;
}

