/* Create two equal columns that floats next to each other */
@page {
  size: A4;
  margin: 0;
}

@media print{
  @page {
    size: A4;
    margin: 0;
  }
  @page {size: landscape}
  html, body {
    width: 210mm;
    margin: -2px !important;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}
/*  */
.main {
	padding-left: 20px;
	padding-right: 20px;
	width: 210mm;
}
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.logo-main {
	width: 100%;
}
.header-main {
	margin-top: 60px;
	padding-top: 20px;
	padding-bottom: 5px;
	background-color: #6F2FA0;
	border-radius: 20px;
	text-align: center ;
	max-width: 85%;
}

.header-doc {
	min-height: 400px !important;
	max-height: 400px !important;
}
.header-cos {
	min-height: 140px !important;
	max-height: 140px !important;
	/* border:1px solid black; */
}
.header-pro {
}
.header-foot {
	margin-top: 20px;
}

.text-white {
	font-size: 3rem;
	color: #ffffff !important;
}

.mar-0 {
	margin: 0;
}
.pad-0 {
	padding: 0;
}

table, td, th {
  border: 1px solid;
	padding: 8px 16px;
}

table {
  border-collapse: collapse;
}

.w-100 {
	width: 100%;
}
.w-70 {
	width: 70%;
}

.header-table {
	margin-top: 40px;
	font-size: 1.1rem;
}

.f-r {
	float: right;
}

.header-mr {
	margin-right: 60px;
}

.t-c {
	text-align: center;
}
.t-r {
	text-align: right;
}

.b-g {
	background-color: #BFBFBF;
}

.cos-main {
	padding:5px 0px;
	margin-bottom: 10px;
}

.table-pro-name {
	min-width: 200px;
}

.table-main {
	min-width: 80px;
	max-width: 80px;
}
.table-main-2 {
	min-width: 110.5px;
	max-width: 110.5px;
}
.table-sec {
	min-width: 80px;
	max-width: 80px;
}

.sign-bar {
	position: relative;
	border-bottom: 1px solid #000000;
	height: 50px;
	margin-bottom: 30px;
}
.sign-img {
	position: absolute;
	width: 200px;
	top: -40px;
	left: 50px;
}