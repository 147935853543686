@page {
  size: A4;
  margin: 0;
}

@media print{
  @page {
    size: A4;
    margin: 0;
  }
  @page {size: landscape}
  html, body {
    width: 210mm;
    margin: -2px !important;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

.bill-7{
  width: 210mm;
  display: block;
  position: relative;

  background-color: white;
  margin-bottom: -1px;
}

.bill-7 .bill-7-field{
  position: relative;
  display: flex;
  width: 210mm;
  height: 297mm;
  page-break-after: avoid;
  page-break-before: avoid;
  margin-bottom: -2px !important;
}

.bill-7 p{
  margin: 0;
}

.bill-7 label{
  margin: 0;
}

.bill-7 .inline{
  display: flex;
  justify-content: space-between;
}

.bill-7 .table-group .table-header{
  display: flex;
  width: 100%;
  border-top: 2px solid;
  border-bottom: 2px solid;
}

.bill-7 .table-body .table-field{
  display: flex;
  width: 100%;
  margin-top: -2px;
}

.bill-7 .table-body .table-field:first-child{
  border-top: 2px solid;
  text-align: left;
}

.bill-7 .table-body .table-field:last-child{
  border-bottom: 2px solid;
}

.bill-7 .table-body .table-field div{
  width: 20%;
  border-left: 2px solid;
  text-align: center;
}

.bill-7 .table-body .table-field div:first-child{
  width: 40%;
  border-left: 2px solid;
  text-align: left;
}

.bill-7 .table-body .table-field div:last-child{
  border-right: 2px solid;
}

.bill-7 .table-group .table-header .title{
  width: 20%;
  text-align: center;
  padding: 5px;
  font-weight: bold;
}

.bill-7 .table-group .table-header .title:first-child{
  width: 40%;
  text-align: center;
  padding: 5px;
  font-weight: bold;
}

.bill-7 .inline p.title{
  width: 80px;
}

.bill-7 .half-left{
  width: 30%;
  padding: 30px 0 0 50px;
}

.bill-7 .half-right{
  width: 70%;
  padding: 30px 50px 0 0;
}

.bill-7 .half-left .logo{
  height: auto !important;
  width: 100%;
  height: auto;
}

.bill-7 .half-left .logo img{
  width: 100%;
}

.bill-7 .half-left .customer-info{

}

.bill-7 .half-left .car-info{

}

.bill-7 .half-left .note{
  margin-top: 10px;
}

.bill-7 .half-right .header{
  width: 100%;
  display: flex;
}

.bill-7 .half-right .header .address{
  width: 60%;
}

.bill-7 .half-right .header .address .line{
  border-bottom: 1px solid;
  height: 100%;
}

.bill-7 .half-right .header .address .line .qr{
  border-left: 10px solid #f82d92;
  margin-left: 30px;
  height: 100%;
  padding-left: 5px;
}

.bill-7 .half-right .header .address .line .qr img{
  width: 100px;
  margin-bottom: 1px;
}

.bill-7 .half-right .header .address .line .qr .phone{
  display: flex;
}

.bill-7 .half-right .header .address .line .qr .phone img{
  width: 30px;
}

.bill-7 .half-right .header .address .line .qr .phone p{
  align-self: center;
}

.bill-7 .half-right .header .order{
  width: 40%;
}

.bill-7 .half-right .header .order .department{
  text-align: right;
}

.bill-7 .half-right .header .order .order-label{
  background-color: #6f6f6f;
  color: white;
  text-align: right;
  padding: 5px;
  font-size: 20px;
  font-weight: 200;
}

.bill-7 .half-right .address-2{
  margin-left: 55px;
}

.bill-7 .half-right .total{
  width: 40%;
  margin-left: auto;
  text-align: center;
}

.bill-7 .half-right .signature{
  display: flex;
  margin-top: 50px;
  width: 60%;
  margin-right: auto;
}

.bill-7 .half-right .signature .employee{
  width: 50%;
  border-top: 2px solid;
  margin: 10px;
  text-align: center;
}

/* .bill-7 .half-right .signature .sale{
  width: 33.33%;
  border-top: 2px solid;
  margin: 10px;
  text-align: center;
} */

.bill-7 .half-right .signature .car-owner{
  width: 50%;
  border-top: 2px solid;
  margin: 10px;
  text-align: center;
}

