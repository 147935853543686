@page {
  size: A4 portrait;
  margin: 0;
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print{
  @page {
    size: A4 portrait;
    margin: 0;
  }
  @page {size: portrait}
  html, body {
    width: 210mm;
    height: 296mm !important;
    border: 1px solid white;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .pagebreak {
    page-break-before: always;
  }
}

.bill-2{
  width: 210mm;
  /* height: 297mm; */
  background-color: white;
  padding: 0 5px 0 30px;
  /* margin-bottom: -1px; */
}

.bill-2-field{
  background-color: white;
  height: 250mm;
}

.bill-2 p{
  margin: 0;
}

.bill-2 label{
  margin: 0;
  font-weight: bold;
}

.bill-2 .inline{
  display: flex;
}

.bill-2 .inline.between{
  justify-content: space-between;
}

.bill-2 .top{
  text-align: center;
}

.bill-2 .detail{
  height: 320px !important;
  max-height: 320px !important;
}

.bill-2 .detail .line{
  display: flex;
  justify-content: space-between;
}

.bill-2 .table-bill-2 .table-head{
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  /* border: 1px solid; */
  font-weight: bold;
}

.bill-2 .table-bill-2 .table-head div:first-child{
  width: 10%;
  border-right: 1px solid;
}

.bill-2 .table-bill-2 .table-head div:nth-child(2){
  width: 30%;
  /* border-right: 1px solid; */
}

.bill-2 .table-bill-2 .table-head div:nth-child(3){
  width: 15%;
  /* border-right: 1px solid; */
}

.bill-2 .table-bill-2 .table-head div:nth-child(4){
  width: 15%;
  border-right: 1px solid;
}

.bill-2 .table-bill-2 .table-head div:last-child{
  width: 30%;
}

.table-bill-2 .body-field{
 height: 340px;
}

.table-bill-2 .table-body{
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  /* border-left: 1px solid; */
  /* border-right: 1px solid; */
}

.bill-2 .table-bill-2 .table-body div:first-child{
  width: 5%;
  /* border-right: 1px solid; */
}

.bill-2 .table-bill-2 .table-body div:nth-child(2){
  width: 55%;
  /* border-right: 1px solid; */
  text-align: start;
}

.bill-2 .table-bill-2 .table-body div:nth-child(3){
  width: 10%;
  /* border-right: 1px solid; */
}

.bill-2 .table-bill-2 .table-body div:nth-child(4){
  width: 12%;
  /* border-right: 1px solid; */
}

.bill-2 .table-bill-2 .table-body div:nth-child(4).bordered{
  border-right: none;
}

.bill-2 .table-bill-2 .table-body div:last-child{
  width: 22%;
}

.bill-2 .table-bill-2 .table-body div.merge{
  width: 75%;
}

.bill-2 .table-bill-2 .table-body.pricing{
  font-weight: bold;
}

.bill-2 .table-bill-2 .table-body.pricing.first .merge{
  border-bottom: none;
}

.bill-2 .table-bill-2 .table-body.pricing.first div:nth-child(2){
  font-weight: normal;
}

.bill-2 .table-bill-2 .table-body.pricing.first .bordered{
  border-bottom: none;
}

.bill-2 .table-bill-2 .table-body.pricing.second div:first-child{
  /* border-bottom: 1px solid; */
}

.bill-2 .table-bill-2 .table-body.pricing.second div:nth-child(2){
  /* border-bottom: 1px solid; */
}

.bill-2 .table-bill-2 .table-body.pricing.last .bordered{
  border-top: none;
  /* border-bottom: 1px solid; */
}

.bill-2 .table-bill-2 .table-body div.bordered{
  /* border-top: 1px solid; */
  /* border-bottom: 1px solid; */
}

.bill-2 .signature{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.bill-2 .signature .signature-field{
  width: 20%;
}

.bill-2 .signature .signature-field .signature-area .signature-name{
  width: 100%;
  /* border-bottom: 1px dotted; */
  margin-bottom: 30px;
}

.bill-2 .signature .signature-field .signature-area .signature-date{
  position: relative;
  display: flex;
  width: 100%;
  /* border-bottom: 1px dotted; */
  margin-top: 30px;
  height: 20px;
}

.bill-2 .signature .signature-field .signature-area .signature-date p{
  position: absolute;
  text-align: end;
  bottom: -45%;
}

.bill-2 .signature .signature-field .signature-area .signature-date p span{
  background: white;
}

.bill-2 .signature .signature-field .signature-area .signature-date p:first-child{
  width: 33%;
}
.bill-2 .signature .signature-field .signature-area .signature-date p:last-child{
  width: 66%;
}

.bill-2 .signature .signature-field .signature-position{
  width: 100%;
  margin-top: 10px;
  text-align: center;
}