@page {
  size: A4 landscape;
  margin: 0;
}

@media print{
  @page {
    size: A4 landscape;
    margin: 0;
  }
  @page {size: landscape}
  html, body {
    width: 297mm;
    height: 210mm;
    border: 1px solid white;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

.bill-4{
  display: flex;
  margin-bottom: -1px;
  width: 297mm;
  height: 210mm;
  background-color: white;
  /* padding: 0 5px 0 5px; */
  padding: 35px;
}

.bill-4 p{
  font-size: 12px;
  margin: 0;
  color: #303663;
}

.bill-4 label{
  font-size: 12px;
  margin: 0;
  color: #303663;
}

.bill-4 .type{
  text-align: end;
}

.bill-4 .model-checkbox{
  position: relative !important;
  margin-right: 5px !important;
  bottom: -0.4em !important;
  border: 1px solid #303663 !important;
  min-width: 16px !important;
  min-height: 16px !important;
  max-width: 16px !important;
  max-height: 16px !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.bill-4 .inline{
  display: flex;
}

.bill-4 .pay-by{
  width: 11%;
}

.bill-4 .inline .label{
  width: 100%;
  max-width: fit-content;
  position: relative;
  bottom: -0.4em;
}

.bill-4 .input-data{
  text-align: center;
  width: 100%;
  border-bottom: 1px dotted #303663 !important;
}

.bill-4 .data-box{
  border: 2px solid #303663;
  width: 100%;
  height: 22px;
  text-align: right;
}

.bill-4 .half-left{
  width: 50%;
  /* padding-left: 5px; */
  padding-right: 10px;
  border-right: 1px dashed darkgray;
}

.bill-4 .half-right{
  width: 50%;
  padding-left: 10px;
  /* padding-right: 5px; */
  border-right: 1px dashed white;
}

.bill-4 .header{
  display: flex;
  text-align: center;
}

.bill-4 .header .group-address{
  border: 2px solid #303663;
  border-radius: 15px;
  margin-right: 10px;
  padding: 5px;
  width: 50%;
}

.bill-4 .header .group-invoice{
  border: 2px solid #303663;
  border-radius: 15px;
  margin-left: 10px;
  padding: 5px;
  width: 50%;
}

/* .bill-4 .group-customer{

} */

.group-table{
  margin-top: 5px;
}

.group-table .header{
  border: 2px solid #303663;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.group-table .header .group-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.group-table .header .group-header .sub-header:first-child{
  border-right: 2px solid #303663;
  width: 10%;
}

.group-table .header .group-header .sub-header:nth-child(2){
  border-right: 2px solid #303663;
  width: 25%;
}

.group-table .header .group-header .sub-header:nth-child(3){
  border-right: 2px solid #303663;
  width: 15%;
}

.group-table .header .group-header .sub-header:nth-child(4){
  border-right: 2px solid #303663;
  width: 15%;
}

.group-table .header .group-header .sub-header:nth-child(5){
  border-right: 2px solid #303663;
  width: 15%;
}

.group-table .header .group-header .sub-header:last-child{
  width: 25%;
}

.group-table .body{
  border-left: 2px solid #303663;
  border-right: 2px solid #303663;
}

.group-table .group-body{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.group-table .group-body:last-child{
  border-bottom: 2px solid #303663;
}

.group-table .body .sub-body{
  /* color: #303663; */
  /* border: 2px solid #303663; */
  /* margin: -2px -1px 0px -1px; */
  font-size: 12px;
}

.group-table .body .sub-body:first-child{
  width: 10%;
  text-align: center;
  border-right: 2px solid #303663;
}

.group-table .body .sub-body:nth-child(2){
  width: 25%;
  border-right: 2px solid #303663;
}

.group-table .body .sub-body:nth-child(3){
  width: 15%;
  align-items: end;
  display: flex;
  justify-content: end;
  /* padding: 0 5px 0 5px; */
  border-right: 2px solid #303663;
}

.group-table .body .sub-body:nth-child(4){
  width: 15%;
  align-items: end;
  display: flex;
  justify-content: end;
  /* padding: 0 5px 0 5px; */
  border-right: 2px solid #303663;
}

.group-table .body .sub-body:nth-child(5){
  width: 15%;
  align-items: end;
  display: flex;
  justify-content: end;
  border-right: 2px solid #303663;
}

.group-table .body .sub-body:last-child{
  width: 25%;
  align-items: end;
  display: flex;
  justify-content: end;
}

.group-table .body .sub-body.conflict{
  display: flex;
  /* width: 100%; */
}

.group-table .body .sub-body.conflict .main{
  /* width: 75%; */
  width: 100%;
  height: 100%;
  /* border-right: 2px solid #303663; */
  align-items: end;
  display: flex;
  justify-content: end;
}

.group-table .body .sub-body.conflict .main p{
  padding: 0 5px 0 5px;
  font-size: 12px;
  width: 100%;
  text-align: end;
}

.group-table .body .sub-body:not(:nth-child(2)) p{
  padding: 0 5px 0 5px;
  font-size: 12px;
  width: 100%;
}

.group-table .body .sub-body:not(:nth-child(1)):not(:nth-child(2)) p{
  text-align: end;
}

/* .group-table .body .sub-body.conflict .intervene{
  width: 25%;
  height: 100%;
} */

.bill-4 .total{
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-end;
}

.bill-4 .total .title{
  margin-right: 5px;
}

.bill-4 .total-character{
  margin-top: 10px;
}

.bill-4 .total-character .title{
  margin-right: 5px;
  width: 100%;
  max-width: fit-content;
}

.bill-4 .total-character .group{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bill-4 .group-note{
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.bill-4 .group-note .note{
  width: fit-content;
  border: 2px solid #303663;
  text-align: right;
  padding: 2.5px;
}
.bill-4 .group-note .signature{
  text-align: center;
  align-self: center;
  width: 50%;
}


