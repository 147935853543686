.employee-dashboard{

  h1,h2,h3,h4,h5{
    margin: 0;
    text-shadow: -1.5px -.5px #f923f9;
    font-weight: bold;
  }

  p,label{
    margin: 0;
    text-shadow: -.5px -.5px #f923f9;
    font-weight: bold;
  }

  label{
    font-weight: bold;
  }

  .group{
    width: 30%;
    .vision{}
    .honor-prize{
      label{
        display: block;
      }
      img{
        max-height: 120px;
        width: 100%;
      }
    }
  }

  .hyper-link{
    width: 70%;
    img{
      max-height: 150px;
      width: 100%;
    }
  }

  .planetary-ring{
    border: 8px solid #79ffa0;
    border-radius: 20px;
    max-height: 250px;
    max-width: 200px;
    padding: 5px 3px 5px 3px;
    .second-planetary-ring{
      display: flex;
      height: 100%;
      border: 8px solid #a134f6;
      border-radius: 15px;
      background-color: black;
      img{
        // align-self: center;
        width: 100%;
        min-width: 150px;
        height: 100%;
      }
    }
  }

  .employee-info{
    width: 100%;
    .branch-code{
      font-size: 70px;
    }
  }

  .mission-life-time{
    justify-content: center;
    display: flex;
    h2{
      border: 8px solid #79ffa0;
      min-width: 70%;
      border-radius: 20px;
      width: fit-content;
      padding-left: 3px;
      padding-right: 3px;
      height: 45px;
    }
  }

  .border-data{
    border: 10px solid #bd86e7;
    border-radius: 20px;
    padding: 20px 25px 20px 25px;
    margin-top: 15px;
    h2{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .group-data{
      border: 3px solid #bd86e7;
      border-radius: 5px;
      padding:  15px 0 15px 0;
      height: 100%;
      h1{
        line-height: 1.5;
      }
      h5{
        line-height: 1.5;
        text-shadow: -.5px -.5px #f923f9;
      }
      img{
        width: 100%;
      }
      &-image{
        padding: 0;
        display: flex;
        align-items: center;
        @extend .group-data;
      }
    }
  }

}