/* html {
  font-size: calc(60% + 0.8vmin);
} */

/* @media screen and (min-width: 998px) {
  .custom-modal .modal-body {
    max-height: 80vh;
  }
} */
@media only screen and (min-width: 1400px) {
  .icon {
    height: 84px;
    width: auto;
  }
}
@media (max-width: 1400px) {
  .icon {
    height: 35px;
  }
}

@media (min-width: 992px) and (max-width: 1279px) {
  .custom-modal .modal-body {
    max-height: 100vh;
  }
  .modal-lg,
  .modal-xl {
    margin: unset;
    max-width: 100vw;
  }
  .group-btn-tabs button {
    font-size: 12px;
  }
  .text-add label {
    white-space: nowrap;
  }
  .branch-info {
    padding: 2rem 1rem 4rem !important;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-dialog-scrollable {
    max-height: unset;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    max-height: unset;
  }
  .custom-modal .modal-header {
    background: #c4c4c4;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 50%), inset 0px 4px 4px rgb(0 0 0 / 50%);
    border-radius: unset;
    padding: 0.5rem;
    justify-content: flex-start;
    position: relative;
    height: 50px;
  }
  .custom-modal .modal-header .modal-title {
    position: absolute;
    left: 2.5rem;
    bottom: 10px;
    display: flex;
  }
  .custom-modal .modal-header .modal-title img {
    width: 24px;
    margin-right: 0.5rem;
  }
  .custom-modal .modal-body {
    padding: 0 1rem 1rem;
    max-height: 100vh;
  }
  .menu-setting {
    justify-content: flex-start;
  }
  .menu-setting button,
  .dash-icon {
    width: 100vw;
    margin-bottom: unset;
    border-radius: unset;
    background: #c4c4c4;
    text-align: left;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    display: inline-flex;
    align-items: center;
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%), 0px 6px 6px rgb(0 0 0 / 25%);
    border-top: 2px solid rgba(122, 122, 122, 0.5);
    border-right: 2px solid rgba(122, 122, 122, 0.5);
    border-left: 2px solid rgba(122, 122, 122, 0.5);
    border-bottom: unset;
  }
  .menu-setting button img,
  .dash-icon .icon,
  .menu-icon {
    width: 30px;
    height: auto;
    margin-right: 10px;
  }
  .dash-icon {
    margin: unset;
    padding: 0.5rem 1rem;
  }
  .dash-icon p {
    margin-bottom: 0;
    color: #000;
    font-weight: 500 !important;
  }
  .box-typecar {
    border-top: unset;
  }
  .box-typecar .add-list {
    width: 100% !important;
    margin-top: 10px;
    justify-content: center;
    padding: 3px 0;
  }
  .custom-table thead {
    border: 2px solid #fff;
  }
  .set-paginate {
    justify-content: center;
    margin-top: 1rem;
  }
  .box-paginate {
    right: 0;
  }
  .custom-setting {
    background-image: url('../../icon/holo-bg.jpg');
  }
  .hidden {
    opacity: 1;
  }
  .edit-text-table {
    padding: 0;
  }
  .fixed-bottom {
    display: block;
    top: 0;
    bottom: unset;
    padding: 3px !important;
    width: 100%;
  }
  .navbar .end-button {
    margin-right: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }
  .main-menu {
    margin-top: 50px;
  }
  .set-text-dark {
    color: #000;
    font-weight: 500;
  }
  .search-table {
    width: 100%;
  }
  .search-label {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  .search-table .css-g1d714-ValueContainer {
    justify-content: flex-start;
  }
  .btn-tabs-search {
    width: 100%;
    margin: 1rem 0 0;
  }
  .custom-table .max-th {
    display: block;
    width: max-content;
  }
  .box-input {
    border-top: unset !important;
  }
  .box-input .input-head {
    padding: 10px !important;
  }
  .border-line {
    border-top: 1px solid #7a7a7a;
  }
  .group-add-list {
    display: block !important;
    height: auto !important;
    padding: 1rem 0 0;
  }
  .serakapoy {
    margin: 0 1rem !important;
  }
  .input-search-device {
    border: 1px solid #7a7a7a !important;
  }
  .group-reverse {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
  }
  .star-platinum {
    transform: translateX(-15%);
  }
  .form-information {
    text-align: left !important;
    padding: unset !important;
    margin-bottom: 10px !important;
  }
  .form-information label,
  .gender-color {
    margin-bottom: 5px !important;
    padding-bottom: unset !important;
    margin-right: 10px;
    padding-top: 5px !important;
  }
  .form-information .box-gender {
    justify-content: flex-start !important;
    align-items: unset !important;
  }
  .text-add {
    text-align: left !important;
    margin-left: unset !important;
    padding: 0 15px !important;
  }
  .custom-btn-add {
    width: 100% !important;
    align-items: center;
    justify-content: center;
  }
  .gold-experience {
    flex-direction: column;
    text-align: center;
    max-width: 400px;
    margin: auto;
  }
  .profile-form {
    padding: 1rem 2rem !important;
    overflow: auto;
  }
  .profile-form label,
  .profile-form p {
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .profile-form .row {
    margin: 0 !important;
  }
  .profile-form .row > div {
    padding: 0 !important;
  }
  .border-tab-top {
    border-top: unset !important;
  }
  .crazy-diamond {
    margin: 0 -15px;
    width: auto !important;
  }
  .crazy-diamond .tabs-item {
    flex: 50%;
    margin-right: unset !important;
    border-color: #fff !important;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    margin-bottom: 2px;
  }
  .crazy-diamond .tabs-item.active,
  .crazy-diamond .tabs-item:hover,
  .crazy-diamond .tabs-item:focus {
    background-color: rgba(122, 122, 122, 0.5) !important;
    border-color: #fff !important;
    box-shadow: unset !important;
  }
  .group-btn-tabs {
    grid-template-columns: unset !important;
    row-gap: 1rem;
    margin-top: 1rem;
  }
  .overall-box {
    padding: 2rem 0;
  }
  .overall-head {
    flex-direction: column;
    align-items: unset;
  }
  .overall-box h1 {
    font-size: 22px;
  }
  .overall-box h2,
  .overall-box h3 {
    font-size: 16px;
  }
  .overall-box .chart-num {
    font-size: 30px;
  }
  .overall-body {
    padding: 0 2rem;
    row-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .set-w-profile {
    max-width: 400px;
    margin: auto !important;
  }
  .set-logout-modal {
    background: #c4c4c4 !important;
    padding: 3rem 0 !important;
  }
  .set-w-100 {
    width: 100%;
  }
  .logout-header {
    flex-direction: row-reverse;
  }
  .logout-header .modal-title {
    left: 1rem !important;
  }
  .set-w-fit {
    width: fit-content !important;
  }
  .overall-body .overall-item {
    justify-content: space-around !important;
  }
  .input-group {
    flex-direction: column;
  }
  .input-group .file-name,
  .input-group .profile-name,
  .input-group .browse {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .overall-body {
    grid-template-columns: unset;
  }
  .profile-form {
    padding: 1rem !important;
  }
  .overall-branch {
    grid-template-columns: unset !important;
    padding: 0 1rem;
  }
  .branch-info {
    padding: 2rem 0 !important;
  }
  .set-w-fit {
    width: 100% !important;
  }
}
.set-col {
  padding: 0 !important;
}
.link {
  color: black;
  text-decoration: none !important;
}
.d-lg-none {
  padding: 0px !important;
}
@media only screen and (max-width: 991px) {
  .navbar {
    transform: translate(0, 00px) !important;
    height: 49px !important;
  }
}
