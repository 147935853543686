.box-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding-bottom: 1rem 0;
}

.text-add {
    text-align: right !important;
    padding: inherit !important;
    margin-top: 0.5rem !important;
}

.text-detail {
    margin-left: -5rem;
}

.text-select {
    margin-left: -3rem;
}

.text-sex {
    margin-right: 1rem;
}

.text-account {
    text-align: end;
    margin-top: auto;
}

.align-account {
    justify-content: center;
    align-items: center;
}

.text-head {
    margin-left: 1rem;
}

/* .modal-height {
    height: 85vh !important;
} */

.row-head {
    text-align: center;
    /* margin-top: 1rem;
    margin-bottom: 1rem;
    position: absolute; */
    /* background: rgba(196, 196, 196, 0.5); */
    background: #a6a6a6;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border: 1px solid #E5E5E5;
    width: 170px;
    height: 40px;
    margin-left: 0.5rem;
    margin-top: -1rem;
    box-sizing: border-box;
    border: 2px solid rgba(229, 229, 229, 0.5);
}

.font-head {
    font-weight: bold;
    font-size: 15px;
}

.btn-footer {
    margin-top: 3rem;
}

.btn-modal-add {
    width: 200px !important;
    display: block !important;
}

.cus-col {
    display: flex;
    align-items: center;
    padding: 0;
}

.cus-col label {
    margin-bottom: unset;
}

.custom-file .custom-file-label::after {
    content: "เลือกไฟล์" !important;
    background: #C4C4C4;
    border: 1px solid rgba(229, 229, 229, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-img-file .custom-file-label::after {
    content: "เลือกรูปโปรไฟล์" !important;
    background: #C4C4C4;
    border: 1px solid rgba(229, 229, 229, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-file-label {
    border-radius: unset;
}