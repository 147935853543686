.custom-calendar{
  display: flex;
  width: 100%;
  background-color: #682c80 !important;
  color: rgba(196, 196, 196, 0.8);
  font-weight: bold;
  min-height: 40px;
  div{
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    width: calc(100%/7);
  }
}

.custom-calendar-year{
  font-size: 35px;
  background-color: #682c80 !important;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  p{
    margin: 0;
    cursor: pointer;
  }

  .left-arrow{
    cursor: pointer;
  }

  .right-arrow{
    cursor: pointer;
  }
}

.custom-calendar-month{
  font-size: 35px;
  background-color: #682c80 !important;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  p{
    margin: 0;
    cursor: pointer;
  }

  .left-arrow{
    cursor: pointer;
  }

  .right-arrow{
    cursor: pointer;
  }
}

.custom-calendar-day{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: white;
  min-height: 70px;
  .group-day{
    // rgba(104, 44, 128, 1)
    cursor: pointer;
    justify-content: center;
    width: calc(100%/7);
    min-height: 70px;
    padding: 5px 10px 5px 10px;
    outline: 1px solid rgba(104, 44, 128, .1);
    outline-offset: 0px;
    .day{
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 15px;
      .left-button{
        display: flex;
        align-items: center;
      }

      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      .number{
        padding-top: 3px;
        font-size: 16px;
        margin: 0;
      }
      .add-icon{
        margin-left: 5px;
        width: 15px !important;
        height: 15px !important;
      }
    }
    .event{
      width: 100%;
      align-items: center;
      text-align: center;
      
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      .content{
        display: flex;
        width: 100%;
        background-color: rgba(104, 44, 128, .5);
        margin: 2px 0 2px 0;
        padding: 2px;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        &.more{
          background-color: rgba(104, 44, 128, .6);
          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    }

    &:hover{
      box-shadow: 0 0 0 0.2rem rgba(104, 44, 128, 1);
    }

    &.isToday{
      .day{
        font-weight: bold;
        color: #682c80 !important;
      }
    }
  }
}

.custom-time-responsive{
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  .current-day{
    min-width: 1366px;
    background-color: #682c80 !important;
    p{
      margin: 0;
      font-size: 35px;
      color: white;
    }
  }
  .custom-calendar-time{
    display: flex;
    min-width: 1366px;
    background-color: #682c80 !important;
    color: rgba(196, 196, 196, 0.8);
    font-weight: bold;
    min-height: 40px;
    .name{
      min-width: 200px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .time{
      align-items: center;
      display: flex;
      justify-content: center;
      width: calc(100%/20);
    }
  }

  .custom-calendar-queue{
    display: flex;
    min-width: 1366px;
    background: white;
    min-height: 40px;
    outline: 1px solid rgba(104, 44, 128, .1);
    outline-offset: 0px;
    .list-name{
      outline: 1px solid rgba(104, 44, 128, .1);
      outline-offset: 0px;
      min-width: 200px !important;
      display: flex;
      align-items: center;
      padding: 5px;
    }
    .list{
      outline: 1px solid rgba(104, 44, 128, .1);
      outline-offset: 0px;
      width: calc(100%/20);
      display: flex;
      align-items: center;
      .duration{
        z-index: 99;
        height: 20px;
        cursor: pointer;
      }
    }
  }
}