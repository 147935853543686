@page {
  size: A4 portrait;
  margin: 0;
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print{
  @page {
    size: A4 portrait;
    margin: 0;
  }
  @page {size: portrait}
  html, body {
    width: 210mm;
    height: 297mm;
    border: 1px solid white;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .pagebreak {
    page-break-before: always;
  }
}

.bill-5{
  margin-bottom: -1px;
  width: 210mm;
  height: 297mm;
  background-color: white;
  padding: 30px;
  padding-left: 15px !important;
}

.bill-5 p{
  margin: 0;
}

.bill-5 label{
  margin: 0;
}

.bill-5 .inline{
  display: flex;
}

.bill-5 .top{
  text-align: center;
}

.bill-5 .detail .line{
  display: flex;
  justify-content: space-between;
}

.bill-5 .location-group{
  display: flex;
  font-weight: bold;
}

.bill-5 .location-group .address{
  border: 1px solid;
  width: 70%;
  /* margin: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 50px;
  border-radius: 15px;
  padding: 10px;
}

.bill-5 .location-group .location{
  border: 1px solid;
  width: 30%;
  margin: 10px;
  min-height: 50px;
  border-radius: 15px;
  padding: 10px;
}

.bill-5 .table-bill-5 .table-head{
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  border: 1px solid;
  font-weight: bold;
}

.bill-5 .table-bill-5 .table-head div:first-child{
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-head div:nth-child(2){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-head div:nth-child(3){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-head div:nth-child(4){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-head div:nth-child(5){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-head div:nth-child(6){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-head div:last-child{
  width: calc(100% / 7);
}

.table-bill-5 .body-field{

}

.table-bill-5 .table-body{
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  border-left: 1px solid;
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-body div:first-child{
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-body div:nth-child(2){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-body div:nth-child(3){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-body div:nth-child(4){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-body div:nth-child(5){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-body div:nth-child(6){
  width: calc(100% / 7);
  border-right: 1px solid;
}

.bill-5 .table-bill-5 .table-body div:last-child{
  width: calc(100% / 7);
}

.bill-5 .table-bill-5 .table-body div:nth-child(4).bordered{
  border-right: none;
}

.bill-5 .table-bill-5 .table-body div.merge{
  width: 30%;
}

.bill-5 .table-bill-5 .table-body.pricing{
  font-weight: bold;
}

.bill-5 .table-bill-5 .table-body.pricing {
  font-weight: bold;
}

.bill-5 .table-bill-5 .table-body.pricing.first {
  border-top: 1px solid;
}

.bill-5 .table-bill-5 .table-body.pricing.last {
  /* border-bottom: 1px solid; */
}

.bill-5 .table-bill-5 .table-body.pricing div:first-child{
  width: calc(100% / 7);
}

.bill-5 .table-bill-5 .table-body.pricing div:nth-child(2){
  width: calc((100% / 7) * 3);
}

.bill-5 .table-bill-5 .table-body.pricing div:nth-child(3){
  text-align: start;
  width: calc((100% / 7) * 2);
}

.bill-5 .table-bill-5 .table-body.pricing div:nth-child(4){
  width: calc(100% / 7);
  border-right: none;
}

.bill-5 .table-bill-5 .table-body.pricing.last div:first-child{
  text-align: left;
}

.bill-5 .table-bill-5 .table-body.payment div{
  text-align: start;
  padding: 2px
}

.bill-5 .table-bill-5 .table-body.payment.first{
  border-top: 1px solid;
}

.bill-5 .table-bill-5 .table-body.payment div:first-child{
  width: calc((100% / 7) * 2);
}

.bill-5 .table-bill-5 .table-body.payment div:nth-child(2){
  width: calc((100% / 7) * 3);
}

.bill-5 .table-bill-5 .table-body.payment div:nth-child(3){
  text-align: start;
  width: calc((100% / 7) * 2);
  border-right: none;
}

.bill-5 .table-bill-5 .table-body.payment.last{
  border-bottom: 1px solid;
}

.bill-5 .model-checkbox{
  position: relative !important;
  margin-right: 5px !important;
  /* bottom: -0.4em !important; */
  align-self: center;
  border: 1px solid !important;
  min-width: 16px !important;
  min-height: 16px !important;
  max-width: 16px !important;
  max-height: 16px !important;
  align-items: center;
  justify-content: center;
  display: flex;
}
.bill-5 .input-field{
  display: flex;
}

.bill-5 .group-text{
  border-right: none !important;
  transform: translate(0, 6px);
}

.bill-5 .group-check{
  transform: translate(0, 6px);
}

.bill-5 .group-text-dot{
  border-right: none !important;
  border-bottom: 1px dotted;
}

.bill-5 .group-text-dot p{
  transform: translate(0, 6px);
}