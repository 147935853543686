@page {
  /* size: A4; */
  margin: 0;
}

@media print{
  /* @page {
    size: A4;
    margin: 0;
  } */
  @page {size: landscape}
  html, body {
    width: 300px;
    height: auto;
    /* border: 1px solid white; */
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

.bill-3{
  width: 380px;
  display: block;
}

.bill-3 .bill-3-body{
  border: 1px solid;
  padding: 5px;
  width: 380px;
}

.bill-3 p{
  margin: 0;
}

.bill-3 .logo{
  width: 100%;
  height: 100%;
}

.bill-3 .dashed{
  /* background-image: linear-gradient(to right, darkgrey 50%, white 50%), linear-gradient(to right, darkgrey 50%, white 50%); */
  /* background-size: 15px 2px;
  background-repeat: repeat-x;
  background-position: top, bottom; */
  border-bottom: 1px dashed;
  padding-right: 0;
  display: flex;
  justify-content: center;
}

.bill-3 .field-name{
  text-align: start;
}

.bill-3 .field-name.branch{ 
  text-align: center;
}

.bill-3 .field-name p{
  padding: 0 5px 0 5px;
  font-weight: 500;
}

.bill-3 .field-items{
  /* background-image: linear-gradient(to right, darkgrey 50%, white 50%), linear-gradient(to right, darkgrey 50%, white 50%); */
  background-size: 15px 1px;
  background-repeat: repeat-x;
  background-position: top, bottom;
  padding: 10px 0px 10px 0px;
}

.bill-3 .field-items .group-items{
  display: flex;
  justify-content: space-between;
}

.bill-3 .field-items .group-items.head{
  border-top: 1px dashed;
  border-bottom: 1px dashed;
}

.bill-3 .field-items .group-items .header{
  width: 17.5%;
  text-align: center;
}

.bill-3 .field-items .group-items .header .qty{
  width: 11.5%;
  text-align: center;
}

.bill-3 .field-items .group-items .header .unit-price{
  width: 20.5%;
  text-align: center;
}

.bill-3 .field-items .group-items .header.title{
  width: 28%;
  text-align: start;
}

.bill-3 .field-items .group-items .header.price{
  width: 23.5%;
  text-align: center;
}

.bill-3 .field-items .group-items .item{
  width: 28%;
  text-align: start;
}

.bill-3 .field-items .group-items .qty{
  width: 11.5%;
  align-self: center;
}

.bill-3 .field-items .group-items .unit-price{
  width: 20.5%;
  align-self: center;
}

.bill-3 .field-items .group-items .disc{
  width: 17.5%;
  align-self: center;
}

.bill-3 .field-items .group-items .price{
  width: 23.5%;
  align-self: end;
}

.bill-3 .field-items .group-items:last-child{
  border-bottom: 1px dashed;
}


.bill-3 .field-prices{
  /* background-image: linear-gradient(to right, darkgrey 50%, white 50%); */
  /* background-size: 15px 1px;
  background-repeat: repeat-x;
  background-position: bottom; */
  padding: 10px 10px 10px 10px;
}

.bill-3 .field-prices .group-prices{
  display: flex;
  justify-content: space-between;
}

.bill-3 .field-prices .group-prices .item.bold{
  font-weight: bold;
}

.bill-3 .field-prices .group-prices .price.line{
  border-bottom: 1px solid;
}

.bill-3 .field-contact{
  text-align: center;
}

