.increase {
  border: none !important;
  background: none !important;
  background-image: url('../icon/add-staff.png') !important;
  background-position: left 7px center !important;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  background: #c4c4c4;
  border: 1px solid rgba(229, 229, 229, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 171px !important;
  height: 41px !important;
  border-radius: 0;
  text-align: center;
  border: 1px solid rgba(229, 229, 229, 0.5) !important;
  color: black !important;
  &:hover {
    color: black !important;
  }
  p {
    padding-left: 20px;
    margin: 0;
  }
}
.button-add-car {
  height: 44px;
  max-width: 100%;
  width: 180px !important;
  background-image: url('../icon/add-staff.png') !important;
  background-position: left 10px center !important;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  background: #c4c4c4;
  border: 1px solid rgba(229, 229, 229, 0.5) !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  color: black !important;
  &:hover {
    color: black !important;
  }
  p {
    padding-left: 35px;
    margin: 0;
  }
}
.increase-staff {
  border: none !important;
  background: none !important;
  background-image: url('../icon/add-staff.png') !important;
  background-position: left 20px center !important;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  background: #c4c4c4;
  border: 1px solid rgba(229, 229, 229, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 171px !important;
  height: 41px !important;
  border-radius: 0;
  text-align: center;
  border: 1px solid rgba(229, 229, 229, 0.5) !important;
  color: black !important;
  &:hover {
    color: black !important;
  }
  p {
    padding-left: 20px;
    margin: 0;
  }
}

.button-icon {
  border: none !important;
  background: none !important;

  background: #c4c4c4;
  border: 1px solid rgba(229, 229, 229, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 171px !important;
  height: 41px !important;
  border-radius: 0;
  text-align: center;
  border: 1px solid rgba(229, 229, 229, 0.5) !important;
  color: black !important;
  font-size: 16px;
  &:hover {
    color: black !important;
  }
  p {
    margin: 0;
    font-size: 16px;
  }
}

.input {
  height: 48px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25) inset;
  background-image: url('../icon/search-bg.png');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 0;
}

.button-menu {
  max-width: 100% !important;
  width: 100% !important;
  height: 46px !important;

  background: #c4c4c4 !important;
  border: 1px solid rgba(229, 229, 229, 0.5) !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  color: black !important;

  p {
    margin: 0;
    color: black;
    margin-left: 5%;
    font-size: 16px;
  }
}
.set-dropdown-division {
}
.set-dropdown {
  display: block;
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

.hide-increase {
  @media only screen and (min-width: 576px) {
    display: none !important;
  }
}

// //////  Drodpown ////////////////@at-root
.css-1s2u09g-control {
  max-width: 100%;
  width: 100%;
  height: 44px !important;
  border-radius: 0 !important;
  text-align: left !important;
}

.css-yt9ioa-option {
  text-align: left;
}
.css-1n7v3ny-option {
  text-align: left;
  background: #c4c4c47c !important;
}
.css-9gakcf-option {
  text-align: left;
  background: #c4c4c4 !important;
}
.css-1pahdxg-control {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25) inset !important;
}
.css-tj5bde-Svg {
  display: none !important;
}

/////////////////////////////
button{
  font-size: 14px !important;
}
.image-add {
  width: 30px !important;
}