.validate-fail{
  border: 1px solid #e3342f !important;
}

.validate-fail-tags .react-tag-input{
  border: 1px solid #e3342f !important;
}

.validate-fail-date .ant-picker{
  border: 1px solid #e3342f !important;
}
.form-check-label{
  color: black !important;
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: transparent !important;
  padding-right: calc(1.5em + .75rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}