@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Prompt', sans-serif !important;

  max-width: auto;
  max-height: 100%;
  height: 100%;
}

.bg-mrk {
  background-image: url('../../images/bg-desktop.jpg') !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover !important;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 49px);
  padding: 0 !important;
}

.bg-mrk-login{
  background-image: url('../../images/bg-desktop.jpg') !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover !important;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: auto;
  min-height: 100vh;
}
